import React, { useContext, useState, useEffect } from 'react'

import { FirebaseContext, withFirebase } from '../Firebase'

const UserTable = () => {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [error, setError] = useState('')
  const firebase = useContext(FirebaseContext)

  useEffect(() => {
    const unsubscribe = firebase.users(
      query => {
        const users = query.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
        }))
        setUsers(users)
        setLoading(false)
      },
      error => {
        setError(error)
        setLoading(false)
      }
    )
    return unsubscribe
  }, [firebase, setUsers])

  return (
    <div className={`b-table has-pagination ${loading ? `is-loading` : ``}`}>
      <div className="table-wrapper has-mobile-cards">
        <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Created</th>
              <th>Verified</th>
              <th>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <UserRow user={user} />
            ))}
          </tbody>
        </table>

        {error && <p>{error}</p>}
      </div>
    </div>
  )
}

const UserRow = ({ user }) => {
  return (
    <tr key={user.data.userId}>
      <td data-label="Name">{user.data.name}</td>
      <td data-label="Email">{user.data.email}</td>
      <td data-label="Created">
        <small
          className="has-text-grey is-abbr-like"
          title={new Date(
            user.data.creationTime.seconds * 1000
          ).toLocaleDateString('en-ZA', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        >
          {new Date(user.data.creationTime.seconds * 1000).toLocaleDateString(
            'en-ZA',
            { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
          )}
        </small>
      </td>
      <td data-label="Verified" className="is-progress-cell">
        <progress max="100" className="progress is-small is-primary" value="79">
          79
        </progress>
      </td>
      <td className="is-actions-cell">
        <div className="buttons is-right">
          <button className="button is-small is-primary" type="button">
            <span className="material-icons">visibility</span>
          </button>
          <button
            className="button is-small is-danger jb-modal"
            data-target="sample-modal"
            type="button"
          >
            <span className="material-icons">delete</span>
          </button>
        </div>
      </td>
    </tr>
  )
}

export default withFirebase(UserTable)
